import Head from 'next/head';
import { faCloudExclamation } from '@fortawesome/pro-solid-svg-icons';
import Image from 'next/image';
import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { FaIconLight } from '../FaIconLight';
import Button from '../common/Button';
import { H1, P } from '../common/Typography';

import classes from './index.module.scss';

import logoImage from '@/images/logo.svg';

type Props = {
  onButtonClick: () => void;
};

export const ErrorPageUI: FC<Props> = ({ onButtonClick }) => {
  const { t } = useTranslation('ErrorBoundary');

  return (
    <>
      <Head>
        <title>{t('Something went wrong!')}</title>
      </Head>
      <div className={classes.container}>
        <nav className={classes.navbar}>
          <a className={classes.logo} href={'/'}>
            <Image src={logoImage} alt="SMSAPI logo" height={40} />
          </a>
        </nav>
        <div className={classes.errorContainer}>
          <FaIconLight icon={faCloudExclamation} size="5x" />
          <div>
            <H1 disableMargin>{t('An error occurred on our end')}</H1>
            <P>
              {t(
                "We're working on it and we'll try to get it fixed as soon as possible.",
              )}
            </P>
          </div>
          <Button
            onClick={() => {
              onButtonClick();
            }}
            label={t('Go back to the home page')}
            buttonType="primary"
          />
        </div>
      </div>
    </>
  );
};
